import { Form, Input } from 'antd'
import React from 'react'
import { GetTrans } from '../../../Utils'
import Service from '../../../Service'
import { Encrypt } from '../../../Utils/encrypted'
import { useNavigate } from 'react-router-dom'
import Stores from '../../../Stores'
import { setToken, setlogin } from '../../../Stores/auth'
import { toast } from 'react-toastify'
import { login } from '../../../Utils/firebase'

const Login = () => {

    const navigate = useNavigate();

    const onFinish = async (valid) => {
        const user = await login(valid.email, valid.password);
        if (user) {
            navigate("/dashboard");
        }

    }
    return (
        <Form
            name="basic"
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
        >
            <div className="bg-white px-10 py-8 rounded-xl w-screen shadow-md max-w-sm">
                <div className="space-y-4">
                    <h1 className="text-center text-2xl font-semibold text-gray-600">
                        Giriş
                    </h1>
                    <div>
                        <Form.Item
                            label={GetTrans("email")}
                            name="email"
                            rules={[{ required: true, message: 'Please input your email!' }, { type: 'email' }]}
                        >
                            <Input className="bg-indigo-50 px-4 py-2 outline-none rounded-md w-full" />
                        </Form.Item>
                    </div>
                    <div>
                        <Form.Item
                            label={GetTrans("password")}
                            name="password"
                            rules={[{ required: true, message: 'Please input your Password!' }]}
                        >
                            <Input.Password className="bg-indigo-50 px-4 py-2 outline-none rounded-md w-full" />
                        </Form.Item>
                    </div>
                </div>
                <button className="mt-4 w-full bg-gradient-to-tr from-blue-600 to-indigo-600 text-indigo-100 py-2 rounded-md text-lg tracking-wide">
                    Giriş
                </button>
            </div>
        </Form>
    )
}

export default Login