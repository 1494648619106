import HomeService from "./HomeService";
import SettingsService from "./SettingsService";
import DebtPaymentService from "./debtPayment";
import UserService from "./userService ";
import wellService from "./wellService";
import wellUserService from "./wellUserService";

export default class Service {
  UserService = new UserService();
  wellService = new wellService();
  wellUserService = new wellUserService();
  DebtPaymentService = new DebtPaymentService();
  HomeService = new HomeService();
  SettingsService = new SettingsService();
}
