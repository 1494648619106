import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import AuthLayout from "../Layout/Auth/AuthLayout";
import Login from "../Layout/Auth/pages/Login";

const AuthRouter = () => {
  const user = localStorage.getItem("user");
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate("/dashboard");
    }
  }, [user, navigate]);

  return !user ? (
    <Routes>
      <Route path="/" element={<AuthLayout />}>
        <Route index={true} element={<Login />} />
        <Route path="*" element={<Login />} />
      </Route>
    </Routes>
  ) : null;
};

export default AuthRouter;
