import React, { useCallback, useEffect, useState } from "react";
import SidebarSubItem from "./SidebarSubItem";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isMobile } from "react-device-detect";
import { setSidebar } from "../../../Stores/site";

const SidebarItem = ({ isActive, title, icon, hasSubpages, subData, path }) => {
    const [isSubpagesShown, setIsSubpagesShown] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const onClick = useCallback(() => {
        navigate(path);
        if (isMobile) {
            dispatch(setSidebar())
        }
    });
    useEffect(() => {
        console.log(isSubpagesShown);
    }, [isSubpagesShown]);
    if (hasSubpages && subData) {
        return (
            <div>
                <li className="relative px-6 py-3">
                    {isActive && (
                        <span
                            className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                            aria-hidden="true"
                        ></span>
                    )}
                    <button
                        className="inline-flex items-center justify-between w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
                        onClick={() => setIsSubpagesShown(!isSubpagesShown)}
                    >
                        <span className="inline-flex items-center">
                            {icon}
                            <span className="ml-4">{title}</span>
                        </span>
                        <svg
                            className="w-4 h-4"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                        >
                            <path
                                fillRule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                    </button>
                    {isSubpagesShown && (
                        <ul
                            className="p-2 mt-2 space-y-2 overflow-hidden text-sm font-medium text-gray-500 rounded-md shadow-inner bg-gray-50 dark:text-gray-400 dark:bg-gray-900"
                            aria-label="submenu"
                        >
                            {subData.map((item, index) => (
                                <SidebarSubItem key={index} subTitle={item.subTitle} path={item.path} />
                            ))}
                        </ul>
                    )}
                </li>
            </div>
        );
    } else {
        return (
            <div>
                <li className="relative px-6 py-3">
                    {isActive && (
                        <span
                            className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                            aria-hidden="true"
                        ></span>
                    )}
                    <NavLink
                        to={path} onClick={() => { onClick() }}
                        className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
                    >
                        {icon}
                        <span className="ml-4">{title}</span>
                    </NavLink>
                    {/* <a
            className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200 "
            href="forms.html"
          >
            {icon}
            <span className="ml-4">{title}</span>
          </a> */}
                </li>
            </div>
        );
    }
};

export default SidebarItem;
