import { Col, DatePicker, Form, InputNumber, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import Service from '../../../../../Service';
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/tr_TR';
import 'dayjs/locale/tr';
import LoadingComp from '../../../Components/loading/LoadingComp';
import { toast } from 'react-toastify';
import moment from 'moment';

const AddEditSettings = ({ setUpdate, setModal, selectedId, userId }) => {
    const [initalValue, setInitalValue] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (selectedId) {
            setLoading(true)
            const service = new Service();
            service.SettingsService.getCurrentPricePerMinute().then((res) => {
                setInitalValue(res)
                setLoading(false)
            }).catch((error) => {
                toast.error(error);
            });
        }
    }, [selectedId])


    const [form] = Form.useForm();
    const onFinish = (value) => {
        const service = new Service();
        value.startedAt = dayjs(value.startedAt).format('YYYY-MM-DD HH:mm:ss');
        value.endAt = dayjs(value.endAt).format('YYYY-MM-DD HH:mm:ss');
        if (selectedId) {
            value["id"] = initalValue.id;
            value["price"] = value.pricePerMinute;
            service.SettingsService.updatePricePerMinute(value).then((res) => {
                setUpdate((prev) => !prev);
                setModal((prev) => !prev);
                toast.success(selectedId ? "Düzenleme Başarılı!" : "Kayıt eklendi.");

            }).catch((error) => {
                toast.error(error);
            })
        } else {
            service.SettingsService.addPricePerMinute(value).then((res) => {
                setUpdate((prev) => !prev);
                setModal((prev) => !prev);
                toast.success(selectedId ? "Düzenleme Başarılı!" : "Kayıt eklendi.");
            }).catch((error) => {
                toast.error(error);
            })
        }
    }

    if (loading) {
        return <LoadingComp />
    }
    return (
        <div className='settings'>
            <Form
                layout="vertical"
                form={form}
                onFinish={onFinish}
                initialValues={initalValue}
            >
                <Row gutter={[8, 8]}>


                    <Col span={12}>
                        <Form.Item
                            label="Dk ücret"
                            name="pricePerMinute"
                            rules={[{ required: true, message: "Dk ücret boş geçilemez." }]}
                        >
                            <InputNumber
                                size='large'
                                placeholder="Tutar ücret"
                                className="bg-indigo-50  outline-none rounded-md w-full"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Başlangıç Tarihi"
                            name="startedAt"
                            rules={[{ required: true, message: "Başlangıç tarihi boş geçilemez." }]}
                        >
                            <DatePicker locale={locale} showTime={{
                                defaultValue: moment("00:00:00", "HH:mm:ss"),
                                format: "HH:mm"
                            }} size='large'
                            />

                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Bitiş Tarihi"
                            name="endAt"
                            rules={[{ required: true, message: "Bitiş tarihi boş geçilemez." }]}
                        >
                            <DatePicker locale={locale} showTime={{
                                defaultValue: moment("00:00:00", "HH:mm:ss"),
                                format: "HH:mm"
                            }} size='large' />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <button
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 me-2 mb-2 rounded"
                            htmlType="submit"
                        >
                            Kaydet
                        </button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default AddEditSettings