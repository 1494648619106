import baseService from "./baseService";

export default class HomeService extends baseService {
  constructor() {
    super();
    this.schemaName = "api";
  }

  getStatistics(data) {
    return this.getObject(this.schemaName, "getStatistics", data);
  }

  getAllUsers(data) {
    return this.getObject(this.schemaName, "getAllUsers", data);
  }

  getFilteredIrrigation(data) {
    return this.postObject(this.schemaName, "getFilteredIrrigation", data);
  }

  getAllPayments(data) {
    return this.postObject(this.schemaName, "getAllPayments", data)
  }
}
