import { Checkbox, Col, DatePicker, Form, InputNumber, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react'
import Service from '../../../../../Service';
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/tr_TR';
import 'dayjs/locale/tr';
import LoadingComp from '../../../Components/loading/LoadingComp';
import { Option } from 'antd/es/mentions';
import { toast } from 'react-toastify';
import moment from 'moment';


const AddEditDebtPayment = ({ setUpdate, setModal, selectedId, userId }) => {
    const [initalValue, setInitalValue] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isDateCheck, setIsDateCheck] = useState(false);
    const [wells, setWells] = useState([]);

    const [form] = Form.useForm();

    useEffect(() => {
        const service = new Service();
        if (selectedId) {
            form.resetFields();
            setLoading(true);
            service.DebtPaymentService.getPayment("paymentId=" + selectedId).then((res) => {
                res.paymentDate = dayjs(res.paymentDate);
                setInitalValue(res);
                setLoading(false);

            }).catch((error) => {
                toast.error(error);
            })
        }
    }, [form, selectedId])




    const onFinish = (value) => {
        const service = new Service();
        value["userId"] = userId;
        if (isDateCheck) {
            value.paymentDate = dayjs(value.paymentDate).format('YYYY-MM-DD HH:mm:ss');
        }
        if (selectedId) {
            value["paymentId"] = initalValue.id;
            service.DebtPaymentService.updatePayment(value).then((res) => {
                setUpdate((prev) => !prev);
                setModal((prev) => !prev);
                toast.success(selectedId ? "Düzenleme Başarılı!" : "Kayıt eklendi.");
            }).catch((error) => {
                toast.error(error);
            });
        } else {
            service.DebtPaymentService.createPayment(value).then((res) => {
                setUpdate((prev) => !prev);
                setModal((prev) => !prev);
                toast.success(selectedId ? "Düzenleme Başarılı!" : "Kayıt eklendi.");
            }).catch((error) => {
                toast.error(error);
            });
        }
    }

    if (loading) {
        return <LoadingComp />
    }

    return (
        <div>
            <Form
                layout="vertical"
                form={form}
                onFinish={onFinish}
                initialValues={initalValue}
            >
                <Row gutter={[8, 8]}>

                    <Col span={12}>
                        <Form.Item
                            label="Tutar"
                            name="amount"
                            rules={[{ required: true, message: "Tutar boş geçilemez." }]}
                        >
                            <InputNumber
                                size='large'
                                placeholder="Tutar ücret"
                                className="bg-indigo-50  outline-none rounded-md w-full"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        {
                            isDateCheck ? (<Form.Item
                                label="Tarih"
                                name="paymentDate"
                                rules={[{ required: true, message: "Tarih boş geçilemez." }]}
                            >
                                <DatePicker locale={locale} size='large' />
                            </Form.Item>) : null
                        }

                    </Col>
                    <Col span={12}>
                        <Checkbox onChange={(e) => { setIsDateCheck(e.target.checked) }}>Farklı bir tarih seç</Checkbox>
                    </Col>

                    <Col span={24}>
                        <button
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 me-2 mb-2 rounded"
                            htmlType="submit"
                        >
                            Kaydet
                        </button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default AddEditDebtPayment