import baseService from "./baseService";

export default class wellService extends baseService {
  constructor() {
    super();
    this.schemaName = "api";
  }

  getwells(data) {
    return this.getObject(this.schemaName, "getwells", data);
  }

  getwell(data) {
    return this.getObject(this.schemaName, "getWell", data);
  }

  Createwell(data) {
    return this.postObject(this.schemaName, "createWell", data);
  }

  updatewell(data) {
    return this.putObject(this.schemaName, "updateWell", data);
  }
  deletewell(data) {
    return this.deleteObject(this.schemaName, "deleteWell", data);
  }
}
