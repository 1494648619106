import React, { useEffect, useState } from 'react'
import DeleteModal from '../../Components/Modal/DeleteModal';
import { Modal, Table } from 'antd';
import { AiOutlinePlus } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import AddUser from './components/addUser';
import Service from '../../../../Service';


const Users = () => {
    const navigate = useNavigate();
    const [selectedId, setSelectedId] = useState(null);
    const [isModal, setIsModal] = useState(false);
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [update, setUpdate] = useState(false);
    const [data, setData] = useState(null);
    const [curentPage, setCurentPage] = useState(1);

    useEffect(() => {
        const service = new Service();
        service.UserService.getusers(`page=${curentPage}&pageSize=50`).then((res) => {
            setData(res)
        })
    }, [curentPage, update])

    const onDeleteUser = (id) => {
        setSelectedId(id);
        setIsDeleteModal((prev) => !prev);
    }

    const columns = [
        {
            title: "Üye Adı",
            dataIndex: "firstName",
            key: "firstName",
        },
        {
            title: "Üye Soyadı",
            dataIndex: "lastName",
            key: "lastName",
        },

        {
            title: "islemler",
            align: "center",
            width: 300,
            render: (text, record) => {
                return {
                    children: (
                        <div className="flex flex-wrap  justify-center">
                            {/* <Link
                                className="bg-lime-500 hover:bg-lime-700 text-white font-bold py-2 px-4 mb-2   me-2 rounded"
                                to={"/dashboard/users/" + record.id}
                            >
                                Detay
                            </Link> */}
                            <button
                                className="bg-red-500 hover:bg-red-700 font-bold py-1 px-2  text-white rounded"
                                onClick={() => onDeleteUser(record.id)}
                            >
                                Sil
                            </button>
                            {/* <button
                                className="bg-cyan-500 hover:bg-cyan-700 font-bold py-2 px-4 mb-2 rounded text-white"
                                onClick={() => onUpdateUser(record.id)}
                            >
                                Düzenle
                            </button> */}
                        </div>
                    ),
                };
            },
        },
    ];


    return (
        <div >
            <div className='flex flex-wrap my-6'>
                <button
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 me-2 mb-2 rounded flex justify-center items-center"
                    onClick={() => {
                        setIsModal((prev) => !prev);
                        setSelectedId(null);
                    }}
                >
                    <AiOutlinePlus /> Üye Ekle
                </button>
            </div>

            <Table
                pagination={{
                    onChange: (pagination) => setCurentPage(pagination),
                    pageSize: 50,
                    total: data?.totalPages * 50,
                }}
                columns={columns}
                dataSource={data?.results}
                scroll={{ x: "100%" }}
                size='small'

                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {
                            console.log("event---->", event.target.className);


                            if (event.target.className === "ant-table-cell ant-table-cell-row-hover") {
                                navigate("/dashboard/users/" + record.id)
                            }
                        },
                    };
                }}
            />

            <DeleteModal
                isShow={isDeleteModal}
                setIsShow={setIsDeleteModal}
                deleteUrl="api/deleteUser?userId="
                selectedId={selectedId}
                setUpdate={setUpdate}
            />

            <Modal
                title={selectedId != null ? "Üye düzenle" : "Üye Ekle"}
                footer={null}
                open={isModal}
                destroyOnClose={true}
                onCancel={() => setIsModal((prev) => !prev)}
            >
                <AddUser
                    setUpdate={setUpdate}
                    setModal={setIsModal}
                    selectedId={selectedId}
                />
            </Modal>
        </div>
    )
}


export default Users