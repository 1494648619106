import { Col, Input, Modal, Row, Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Service from "../../../../Service";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { FaUsers } from "react-icons/fa";
import { Decrypt } from "../../../../Utils/encrypted";
import { GiWell } from "react-icons/gi";
import './home.css'
import { isMobile } from "react-device-detect";

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(true);
  const [userData1, setUserData1] = useState([]);
  const [userData2, setUserData2] = useState([]);
  const [userData3, setUserData3] = useState([]);
  const [wellData, setWellData] = useState([]);
  const [curentPage, setCurentPage] = useState(1);

  useEffect(() => {
    const service = new Service();
    service.HomeService.getStatistics().then((res) => {
      if (res?.code !== "ERR_NETWORK") setWellData(res);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const service = new Service();
    service.HomeService.getAllUsers(`page=${-1}&pagesize=100`).then(
      (res) => {
        if (res?.code !== "ERR_NETWORK") {

          const data1 = res.result.slice(0, 30);
          const data2 = res.result.slice(30, 60);
          const data3 = res.result.slice(60, 90);

          if (isMobile) {
            setUserData1(res.result)
          }
          else {
            setUserData1(data1);
          }

          setUserData2(data2);
          setUserData3(data3);
        }
        setLoading1(false);
      }
    );
  }, [curentPage]);

  const data = {
    "result": [
      {
        "firstName": "Abdullah",
        "lastName": "Akyer",
        "debt": -240
      },
      {
        "firstName": "Abdurrahman",
        "lastName": "Çakmakkaya",
        "debt": 360
      },
      {
        "firstName": "Abdurrahman",
        "lastName": "Öz",
        "debt": -480
      },
      {
        "firstName": "Adnan",
        "lastName": "Özbay",
        "debt": 0
      },
      {
        "firstName": "Ahmet",
        "lastName": "Akyer",
        "debt": 0
      },
      {
        "firstName": "Ahmet",
        "lastName": "Kürkçü",
        "debt": -780
      },
      {
        "firstName": "Ahmet",
        "lastName": "Sarıkaya",
        "debt": 0
      },
      {
        "firstName": "Ahmet Baki",
        "lastName": "Sarı",
        "debt": 0
      },
      {
        "firstName": "Ali",
        "lastName": "Özay",
        "debt": 0
      },
      {
        "firstName": "Ali",
        "lastName": "Çakır",
        "debt": 0
      },
      {
        "firstName": "Ali",
        "lastName": "Akyer",
        "debt": 0
      },
      {
        "firstName": "Ali",
        "lastName": "Doğan",
        "debt": 0
      },
      {
        "firstName": "Baris",
        "lastName": "Sarikaya",
        "debt": 0
      },
      {
        "firstName": "Bilal",
        "lastName": "Sarıkaya",
        "debt": -360
      },
      {
        "firstName": "Burhan",
        "lastName": "Karataş",
        "debt": 0
      },
      {
        "firstName": "Bülent",
        "lastName": "Toker",
        "debt": -1740
      },
      {
        "firstName": "Cemal",
        "lastName": "Akyer",
        "debt": 120
      },
      {
        "firstName": "Elmas",
        "lastName": "Sarıkaya",
        "debt": 0
      },
      {
        "firstName": "Emin",
        "lastName": "Özaydın",
        "debt": -810
      },
      {
        "firstName": "Ersin",
        "lastName": "Sarıkaya",
        "debt": 0
      },
      {
        "firstName": "Faruk",
        "lastName": "Öz",
        "debt": -5640
      },
      {
        "firstName": "H.İbrahim",
        "lastName": "Öz",
        "debt": -450
      },
      {
        "firstName": "Halil",
        "lastName": "Kara",
        "debt": 0
      },
      {
        "firstName": "Hasan",
        "lastName": "Kutluay",
        "debt": 0
      },
      {
        "firstName": "Hasan",
        "lastName": "Demirağ",
        "debt": -150
      },
      {
        "firstName": "Hasan (Ra)",
        "lastName": "Sarıkaya",
        "debt": 0
      },
      {
        "firstName": "Hasan (Şa)",
        "lastName": "Sarıkaya",
        "debt": -870
      },
      {
        "firstName": "Hüsem",
        "lastName": "Öz",
        "debt": -1440
      },
      {
        "firstName": "Hüsem",
        "lastName": "Akyer",
        "debt": 0
      },
      {
        "firstName": "Hüsem",
        "lastName": "Öner",
        "debt": 0
      },
      {
        "firstName": "Hüseyin",
        "lastName": "Önen",
        "debt": 0
      },
      {
        "firstName": "İbrahim",
        "lastName": "Sarıkaya",
        "debt": 0
      },
      {
        "firstName": "İbrahim",
        "lastName": "Tuğral",
        "debt": -1020
      },
      {
        "firstName": "İbrahim",
        "lastName": "Özay",
        "debt": 0
      },
      {
        "firstName": "İbrahim",
        "lastName": "Özalp",
        "debt": 0
      },
      {
        "firstName": "İbrahim",
        "lastName": "Faydalı",
        "debt": -360
      },
      {
        "firstName": "İbrahim",
        "lastName": "Akyer",
        "debt": 90
      },
      {
        "firstName": "İrfan",
        "lastName": "Karataş",
        "debt": -510
      },
      {
        "firstName": "İsmet",
        "lastName": "Akyer",
        "debt": 0
      },
      {
        "firstName": "M.Ali",
        "lastName": "Öz",
        "debt": -300
      },
      {
        "firstName": "Mehmet",
        "lastName": "Sarıkaya",
        "debt": 0
      },
      {
        "firstName": "Mehmet",
        "lastName": "Öz",
        "debt": 0
      },
      {
        "firstName": "Mehmet",
        "lastName": "Gökçen",
        "debt": 0
      },
      {
        "firstName": "Mehmet",
        "lastName": "Akyer",
        "debt": -360
      },
      {
        "firstName": "Mevlüt",
        "lastName": "Öz",
        "debt": -5310
      },
      {
        "firstName": "Muhammet",
        "lastName": "Önen",
        "debt": 0
      },
      {
        "firstName": "Muharrem",
        "lastName": "Sarı",
        "debt": 0
      },
      {
        "firstName": "Muhtar",
        "lastName": "Köy",
        "debt": 0
      },
      {
        "firstName": "Musa",
        "lastName": "Akyer",
        "debt": 0
      },
      {
        "firstName": "Mustafa",
        "lastName": "Toker",
        "debt": -1110
      }
    ],
    "totalPages": 4,
    "currentPage": 1
  };

  if (loading && loading1) {
    return <div>Yükleniyor...</div>;
  }


  const irrigationsDataColums = [
    {
      title: "Tarih",
      dataIndex: "year",
      key: "year",
    },
    {
      title: "Toplam Sulama",
      dataIndex: "totalIrrigation",
      key: "totalIrrigation",
      render: (text, record) => {
        return <span>{text + " dk"}</span>;
      },
    },
    {
      title: "Toplam Borç",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (text, record) => {
        return <span>{text + "₺"}</span>;
      },
    },
    {
      title: "Toplam Ödeme",
      dataIndex: "totalPayment",
      key: "totalPayment",
      render: (text, record) => {
        return <span>{text + "₺"}</span>;
      },
    },
  ];

  const userDataColums = [
    {
      title: "Adı",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Soyadı",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Borç",
      dataIndex: "debt",
      key: "debt",
      render: (text, record) => {
        return {
          props: {
            style: {
              background:
                record.debt < 0 ? "red" : record.debt > 0 ? "green" : "white",
              color: record.debt !== 0 ? "white" : "black",
              fontWeight: "900",
            },
          },
          children: <div>{text + "₺"}</div>,
        };
      },
    },
  ];

  return (
    <div className="home">
      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
        }}
      >
        <Col xs={12} sm={12} md={8} lg={6}>
          <Link
            href="#"
            className="flex flex-row items-cente border p-4 text-white hover:text-white border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 bg-orange-600"
          >
            <FaUsers className="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-24 md:rounded-none md:rounded-l-lg" />
            <div className="flex flex-col justify-between p-4 leading-normal">
              <h5 className="mb-2 text-2xl font-bold tracking-tight">
                {wellData?.users}
              </h5>
            </div>
          </Link>
        </Col>
        <Col xs={12} sm={12} md={8} lg={6}>
          <Link
            href="#"
            className="flex flex-row items-cente border p-4 text-white hover:text-white border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 bg-red-600"
          >
            <GiWell className="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-24 md:rounded-none md:rounded-l-lg" />
            <div className="flex flex-col justify-between p-4 leading-normal">
              <h5 className="mb-2 text-2xl font-bold tracking-tight">
                {wellData?.wells}
              </h5>
            </div>
          </Link>
        </Col>
        <div class="row-span-2 col-span-2 ...">
          <p className="">Yıllara Göre toplam borçlar ve toplam ödenenler</p>
          <Table

            scroll={{ x: "100%" }}
            dataSource={wellData?.irrigations}
            columns={irrigationsDataColums}
            pagination={false}           // pagination={{
          //   // pageSize: 2,
          //   // total: wellData?.irrigations?.length,
          // }}
          />
        </div>
      </Row>
      <div className="grid grid-rows-2 grid-flow-col gap-4 mt-4">
        <div class="row-span-3 ...">
          <p className="">Bu yıla göre borçlar Listesi</p>
          <Table
            size="small"
            dataSource={userData1}
            // dataSource={userData?.result}
            columns={userDataColums}
            pagination={false}
          />
        </div>

        {
          isMobile ? null : <>
            <div class="row-span-3 ...">
              <p className="">&nbsp;</p>
              <Table
                size="small"
                dataSource={userData2}
                // dataSource={userData?.result}
                columns={userDataColums}
                pagination={false}
              />
            </div>

            <div class="row-span-3 ...">
              <p className="">&nbsp;</p>
              <Table
                size="small"
                dataSource={userData3}
                // dataSource={userData?.result}
                columns={userDataColums}
                pagination={false}
              />
            </div>

          </>
        }

      </div>

    </div >
  );
};

export default Home;
