import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import AuthLayout from "../Layout/Auth/AuthLayout";
import Login from "../Layout/Auth/pages/Login";
import Home from "../Layout/Admin/pages/Home/Home";
import PublicLayout from "../Layout/public/PublicLayout";

const PublicRouter = () => {
  const user = localStorage.getItem("user");
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate("/dashboard");
    }
  }, [user, navigate]);

  return !user ? (
    <Routes>
      <Route path="/" element={<PublicLayout />}>
        <Route index={true} element={<Home />} />
      </Route>
    </Routes>
  ) : null;
};

export default PublicRouter;
