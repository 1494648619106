import React, { useEffect, useState } from 'react'
import DeleteModal from '../../Components/Modal/DeleteModal';
import { Col, DatePicker, Form, Input, Modal, Row, Select, Table } from 'antd';
import { AiOutlinePlus } from 'react-icons/ai';
import Service from '../../../../Service';
import AddEditSettings from './components/addEditSettings';
import './settings.css'
import moment from 'moment';

const Settings = () => {
    const [form] = Form.useForm();
    const [selectedId, setSelectedId] = useState(null);
    const [data, setData] = useState([]);
    const [isModal, setIsModal] = useState(false);
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [update, setUpdate] = useState(false);

    useEffect(() => {
        const service = new Service();
        service.SettingsService.getPricesPerMinute().then((resp) => {
            if (resp.code !== "ERR_NETWORK") setData(resp);
        })
    }, [update])

    const onDeleteWell = (id) => {
        setSelectedId(id);
        setIsDeleteModal((prev) => !prev);
    }

    const onUpdateWell = (id) => {
        setSelectedId(id);
        setIsModal((prev) => !prev);
    }


    const columns = [
        {
            title: "DK ücret",
            dataIndex: "pricePerMinute",
            key: "pricePerMinute",
        },

        {
            title: "Başlangıç Tarih",
            dataIndex: "startedAt",
            key: "startedAt",
            render: (date) => {
                return {
                    children: (
                        <span>
                            {moment(date).format("LLL")}
                        </span>
                    ),
                };
            }
        },
        {
            title: "Bitiş Tarihi",
            dataIndex: "endAt",
            key: "endAt",
            render: (date) => {
                return {
                    children: (
                        <span>
                            {moment(date).format("LLL")}
                        </span>
                    ),
                };
            }
        },
        {
            title: "İslemler",
            align: "center",
            render: (text, record) => {
                return {
                    children: (
                        <div className="flex flex-wrap">
                            <button
                                className="bg-red-500 hover:bg-red-700 font-bold py-2 px-4 mb-2 me-1 text-white rounded"
                                onClick={() => onDeleteWell(record?.id)}
                            >
                                Sil
                            </button>
                            <button
                                className="bg-cyan-500 hover:bg-cyan-700 font-bold py-2 px-4 mb-2 rounded text-white"
                                onClick={() => onUpdateWell(record?.id)}
                            >
                                Düzenle
                            </button>
                        </div>
                    ),
                };
            },
        },
    ];


    return (
        <div className='settings'>
            <div className='flex flex-wrap my-6'>
                <button
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 me-2 mb-2 rounded flex justify-center items-center"
                    onClick={() => {
                        setIsModal((prev) => !prev);
                        setSelectedId(null);
                    }}
                >
                    <AiOutlinePlus /> DK ücret Ekle
                </button>
            </div>

            <Table
                columns={columns}
                dataSource={data}
                scroll={{ x: "100%" }}
            />

            <DeleteModal
                isShow={isDeleteModal}
                setIsShow={setIsDeleteModal}
                deleteUrl="api/deletePricePerMinute?id="
                selectedId={selectedId}
                setUpdate={setUpdate}
            />
            <Modal
                title={selectedId != null ? "DK Ücret Düzenle" : "DK Ücret Ekle"}
                footer={null}
                open={isModal}
                destroyOnClose={true}
                onCancel={() => setIsModal((prev) => !prev)}
            >
                <AddEditSettings
                    setUpdate={setUpdate}
                    setModal={setIsModal}
                    selectedId={selectedId}
                />
            </Modal>
        </div>
    )
}

export default Settings;
