import baseService from "./baseService";

export default class wellUserService extends baseService {
  constructor() {
    super();
    this.schemaName = "api";
  }

  getIrrigation(data) {
    return this.getObject(this.schemaName, "getIrrigation", data);
  }

  getIrrigationsForUser(data) {
    return this.getObject(this.schemaName, "getIrrigationsForUser", data);
  }

  createIrrigation(data) {
    return this.postObject(this.schemaName, "createIrrigation", data);
  }

  updateIrrigation(data) {
    return this.putObject(this.schemaName, "updateIrrigation", data);
  }
  deleteIrrigation(data) {
    return this.deleteObject(this.schemaName, "deleteIrrigation", data);
  }
}
