import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import AdminLayout from "../Layout/Admin/AdminLayout";
import Home from "../Layout/Admin/pages/Home/Home";
import Users from "../Layout/Admin/pages/Users/users";
import Well from "../Layout/Admin/pages/well/well";
import UserInfo from "../Layout/Admin/pages/UserInfo/UserInfo";
import Settings from "../Layout/Admin/pages/settings/settings";
import Report from "../Layout/Admin/pages/report/report";

const PrivateRouter = () => {
  const navigate = useNavigate();
  const user = localStorage.getItem("user");

  useEffect(() => {
    if (!user) {
      navigate("/auth/");
    }
  }, [navigate, user]);

  return (
    <Routes>
      <Route element={<AdminLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/users">
          <Route index element={<Users />} />
          <Route path=":id" element={<UserInfo />} />
        </Route>
        <Route path="/well" element={<Well />} />
        <Route path="/report" element={<Report />} />
        <Route path="/Settings" element={<Settings />} />
      </Route>
    </Routes>
  );
};

export default PrivateRouter;
