import { Modal } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import Service from "../../../../Service";
import baseService from "../../../../Service/baseService";

const DeleteModal = ({
  selectedId,
  deleteUrl,
  setIsShow,
  isShow,
  setUpdate,
}) => {
  const onDelete = useCallback(() => {
    const service = new baseService();
    var url = "https://api.mobsmile.com/sulama/" + deleteUrl + selectedId;
    service.callServiceMethod(url, null, "DELETE").then((res) => {
      setUpdate((prev) => !prev);
      setIsShow((prev) => !prev);
    });
  }, [deleteUrl, selectedId, setUpdate]);

  return (
    <Modal
      title="Silme işlemi"
      open={isShow}
      destroyOnClose={true}
      footer={null}
      onCancel={() => setIsShow((prec) => !prec)}
    >
      <div>
        <h3 className="">Silmek istediğinize eminmisiniz?</h3>
        <div className="flex flex-wrap justify-end items-center mt-4">
          <button
            className="bg-red-500 hover:bg-red-700 px-4 py-2 me-3 rounded text-white "
            onClick={onDelete}
          >
            Evet
          </button>
          <button
            className="bg-gray-500 hover:bg-gray-700 px-4 py-2 rounded text-white "
            onClick={() => setIsShow(false)}
          >
            Hayır
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
