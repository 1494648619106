import React from "react";
import { Link } from "react-router-dom";

const SidebarSubItem = ({ subTitle, path }) => {
    return (
        <li className="px-2 py-1 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200">
            <Link to={path} className="w-full">
                {subTitle}
            </Link>
        </li>
    );
};

export default SidebarSubItem;
