import axios from "axios";
import { Decrypt, Encrypt } from "../Utils/encrypted";
import Stores from "../Stores";
import { refreshToken, setlogout } from "../Stores/auth";
import { toast } from "react-toastify";
import moment from "moment";
import { RefleshToken } from "../Utils/firebase";

const globalApiURL = "https://api.mobsmile.com/sulama/";
export default class baseService {
  postObject(schemaName, funcName, entity) {
    var url = globalApiURL + schemaName + "/" + funcName;
    return this.callServiceMethod(url, entity, "POST");
  }



  getObject(schemaName, funcName, data, cancelToken) {
    var url =
      globalApiURL + schemaName + "/" + funcName + "?" + (data ? data : "");
    return this.callServiceMethod(url, data, "GET", cancelToken);
  }

  getIdObject(schemaName, funcName, data) {
    var url =
      globalApiURL + schemaName + "/" + funcName + "/" + (data ? data : "");
    return this.callServiceMethod(url, data, "GET");
  }

  putObject(schemaName, funcName, entity) {
    var url = globalApiURL + schemaName + "/" + funcName;
    return this.callServiceMethod(url, entity, "PUT");
  }

  deleteObject(schemaName, funcName, data) {
    var url =
      globalApiURL + schemaName + "/" + funcName + "/" + (data ? data : "");
    return this.callServiceMethod(url, null, "DELETE");
  }

  patchObject(schemaName, funcName, entity) {
    var url = globalApiURL + schemaName + "/" + funcName;
    return this.callServiceMethod(url, entity, "PATCH");
  }
  patchUrl(schemaName, funcName, data) {
    var url =
      globalApiURL + schemaName + "/" + funcName + "?" + (data ? data : "");
    return this.callServiceMethod(url, null, "PATCH");
  }

  callServiceMethod(url, data, method, cancelToken) {
    const userDec = localStorage.getItem("user");
    const user = userDec ? JSON.parse(Decrypt(userDec)) : null;
    const currentDate = moment().utc();

    if (!user) {
      Stores.dispatch(setlogout());
    } else {
      const refreshTokenExpiration = moment(
        user.stsTokenManager.expirationTime
      );
      if (refreshTokenExpiration < currentDate) {
        Stores.dispatch(setlogout());
      }

      const accessTokenExpiration = moment(user.accessTokenExpiration).utc();

      if (accessTokenExpiration < currentDate) {
        const newtokn = RefleshToken(user.stsTokenManager.refreshToken);
        console.log("newtokn--->", newtokn);
      }
    }

    const tokenDec = localStorage.getItem("tkn");
    const token = tokenDec ? Decrypt(tokenDec) : null;

    var headers = {
      Authorization: token ? "Bearer " + JSON.parse(token) : "",
    };

    return this.callAnyServiceMethod(url, data, method, headers, cancelToken);
  }

  async callAnyServiceMethod(url, data, method, headers, cancelToken) {
    var authOptions = {
      method,
      url,
      data,
      headers,
      withCredentials: false,
      cancelToken,
    };

    return await axios(authOptions)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  }
}
