import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./Components/Sidebar";
import Stores from "../../Stores";
import Navbar from "./Components/Navbar";

const AdminLayout = () => {
  const isDark = Stores.getState("dark").site.dark;

  useEffect(() => {
    const isHtmlDark = document
      .querySelector("#theme_html")
      .classList.contains("dark");

    if (isDark && !isHtmlDark) {
      document.querySelector("#theme_html").classList.add("dark");
    } else if (!isDark && isHtmlDark) {
      document.querySelector("#theme_html").classList.remove("dark");
    }

    // document.querySelector("#theme_html").classList.toggle("dark");
  }, [isDark]);

  return (
    <div className="flex w-full">
      <Sidebar />

      <div className="right w-full flex flex-col h-screen">
        <Navbar />
        <div className="dark:dark-scrollbar light-scrollbar overflow-y-auto overflow scroll-auto  bg-slate-100 dark:bg-zinc-900  h-screen p-3 dark:text-gray-300">
          <div className="force-overflow" style={{ minHeight: "450px" }}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
