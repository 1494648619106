import { createSlice } from "@reduxjs/toolkit";
import { isMobile } from "react-device-detect";

export const site = createSlice({
  name: "site",
  initialState: {
    dark: false,
    sidebar: !isMobile,
    language: localStorage.getItem("lang") ?? "EN",
  },
  reducers: {
    setDarkMode: (state) => {
      state.dark = !state.dark;
    },
    setLang: (state, action) => {
      state.language = action.payload;
    },
    setSidebar: (state) => {
      state.sidebar = !state.sidebar;
    },
  },
});

export const { setDarkMode, setLang, setSidebar } = site.actions;

export default site.reducer;
