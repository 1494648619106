import { createSlice } from "@reduxjs/toolkit";
import Service from "../Service";
import { Decrypt, Encrypt } from "../Utils/encrypted";

export const auth = createSlice({
  name: "auth",
  initialState: {
    user: null,
    token: null,
  },
  reducers: {
    setlogin: (state, action) => {
      localStorage.setItem("user", action.payload);
      state.user = action.payload;
    },

    setlogout: (state) => {
      localStorage.removeItem("user");
      localStorage.removeItem("tkn");
      state.user = null;
      state.token = null;
    },

    setToken: (state, action) => {
      localStorage.setItem("tkn", action.payload);
      state.token = action.payload;
    },
    refreshToken: async (state, action) => {
      const service = new Service();
      const getuser = localStorage.getItem("user");
      if (getuser) {
        const rToken = {
          RefreshToken: JSON.parse(Decrypt(getuser)).refreshToken,
        };
        try {
          const resq = await service.AuthService.CreateTokenByRefreshToken(
            rToken
          );
          console.log(resq);
          localStorage.removeItem("user");
          localStorage.removeItem("tkn");
          localStorage.setItem(
            "tkn",
            Encrypt(JSON.stringify(resq.data.accessToken))
          );
          localStorage.setItem("user", Encrypt(JSON.stringify(resq.data)));
        } catch (err) {
          console.log(err);
        }
      }
    },
  },
});

export const { setlogin, setlogout, setToken, refreshToken } = auth.actions;

export default auth.reducer;
