import { Modal, Table, Tabs } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { Link, useParams } from "react-router-dom";
import DeleteModal from "../../Components/Modal/DeleteModal";
import Service from "../../../../Service";
import AddEditWellUser from "./components/addEditIrrigation";
import moment from "moment";
import "moment/locale/tr";
import DebtPayment from "./components/DebtPayment";
import Irrigation from "./components/Irrigation";
import './userinfo.css'
import AddUser from "../Users/components/addUser";

const UserInfo = () => {
  moment().locale("tr");

  const [updateList, setUpdateList] = useState(false);
  const [userInfoData, setUserInfoData] = useState(false);
  const { id } = useParams();
  const [isModal, setIsModal] = useState(false);

  useEffect(() => {
    const service = new Service();
    service.UserService.getuser("userId=" + id).then((resp) => {
      setUserInfoData(resp[0]);
    });
  }, [id, updateList]);

  const items = [
    {
      key: "1",
      label: `Borçlar`,
      children: <Irrigation setUpdateList={setUpdateList} />,
    },
    {
      key: "2",
      label: `Ödemeler`,
      children: <DebtPayment setUpdateList={setUpdateList} />,
    },
  ];

  const DatePrice = [
    {
      title: "Tarih",
      dataIndex: "year",
      key: "year",
    },
    {
      title: "Toplam Tutar",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (text, record) => {
        return <span>{text + "₺"}</span>;
      },
    },
  ];

  const DatePriceFree = [
    {
      title: "ödeme Yılı",
      dataIndex: "paymentYear",
      key: "paymentYear",
    },
    {
      title: "Kalan Borç",
      dataIndex: "remainingAmount",
      key: "remainingAmount",
      render: (text, record) => {
        return <span>{text + "₺"}</span>;
      },
    },
    {
      title: "Toplam Borç",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (text, record) => {
        return <span>{text + "₺"}</span>;
      },
    },
    {
      title: "Ödenen",
      dataIndex: "totalPaid",
      key: "totalPaid",
      render: (text, record) => {
        return <span>{text + "₺"}</span>;
      },
    },
  ];

  const onUpdateUser = () => {
    setIsModal((prev) => !prev);
  }

  return (
    <div className="userinfo">
      <div class="w-full rounded-xl p-4 md:w-4/12 lg:w-3/12 bg-white mb-2">
        <div class="grid grid-cols-1 gap-6 lg:grid-cols-12 ">
          <div class="col-span-1 lg:col-span-9">
            <div class="text-center lg:text-left">
              <h2 class="text-2xl font-bold text-zinc-700">
                {userInfoData?.firstName} {userInfoData?.lastName}
              </h2>
              <button
                className="bg-cyan-500 hover:bg-cyan-700 font-bold py-2 px-4 mb-2 rounded text-white"
                onClick={() => onUpdateUser()}
              >
                Düzenle
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="grid grid-rows-2 grid-flow-col gap-4 home">
        <div class="row-span-3 ...">
          <p className="">Yıllara Göre Toplam Borç</p>

          <Table
            scroll={{ x: "100%" }}
            dataSource={userInfoData?.DatePrice}
            columns={DatePrice}
          />
        </div>

        <div class="row-span-2 col-span-2 ...">
          <p className="">
            Yıllara Göre kalan borç, Toplam borç, Toplam ödenen Tablosu
          </p>
          <Table
            scroll={{ x: "100%" }}
            dataSource={userInfoData?.DatePriceFree}
            columns={DatePriceFree}
          />
        </div>
      </div>

      <Tabs defaultActiveKey="1" items={items} />

      <Modal
        title={id != null ? "Üye düzenle" : "Üye Ekle"}
        footer={null}
        open={isModal}
        destroyOnClose={true}
        onCancel={() => setIsModal((prev) => !prev)}
      >
        <AddUser
          setUpdate={setUpdateList}
          setModal={setIsModal}
          selectedId={id}
        />
      </Modal>
    </div>
  );
};

export default UserInfo;
