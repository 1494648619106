import baseService from "./baseService";

export default class UserService extends baseService {
  constructor() {
    super();
    this.schemaName = "api";
  }

  getusers(data) {
    return this.getObject(this.schemaName, "getusers", data);
  }

  getuser(data) {
    return this.getObject(this.schemaName, "getuser", data);
  }

  CreateUser(data) {
    return this.postObject(this.schemaName, "CreateUser", data);
  }

  updateUser(data) {
    return this.putObject(this.schemaName, "updateUser", data);
  }
  deleteUser(data) {
    return this.deleteObject(this.schemaName, "deleteUser", data);
  }
}
