import { Modal, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import AddEditWell from './components/addEditWell';
import DeleteModal from '../../Components/Modal/DeleteModal';
import Service from '../../../../Service';

const Well = () => {
    const [selectedId, setSelectedId] = useState(null);
    const [isModal, setIsModal] = useState(false);
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [update, setUpdate] = useState(false);
    const [data, setData] = useState(false);
    const [curentPage, setCurentPage] = useState(1);

    useEffect(() => {
        const service = new Service();
        service.wellService.getwells(`page=${curentPage}&pagesize=50`).then((res) => {
            setData(res)
        })
    }, [curentPage, update])

    const onDeleteWell = (id) => {
        setSelectedId(id);
        setIsDeleteModal((prev) => !prev);
    }

    const onUpdateWell = (id) => {
        setSelectedId(id);
        setIsModal((prev) => !prev);
    }

    const columns = [
        {
            title: "Kuyu Adı",
            dataIndex: "name",
            key: "name",
        },


        {
            title: "islemler",
            align: "center",
            render: (text, record) => {
                return {
                    children: (
                        <div className="flex flex-wrap">
                            <button
                                className="bg-red-500 hover:bg-red-700 font-bold py-2 px-4 mb-2 me-1 text-white rounded"
                                onClick={() => onDeleteWell(record.id)}
                            >
                                Sil
                            </button>
                            <button
                                className="bg-cyan-500 hover:bg-cyan-700 font-bold py-2 px-4 mb-2 rounded text-white"
                                onClick={() => onUpdateWell(record.id)}
                            >
                                Düzenle
                            </button>
                        </div>
                    ),
                };
            },
        },
    ];


    return (
        <div>
            <div className='flex flex-wrap my-6'>
                <button
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 me-2 mb-2 rounded flex justify-center items-center"
                    onClick={() => {
                        setIsModal((prev) => !prev);
                        setSelectedId(null);
                    }}
                >
                    <AiOutlinePlus /> Kuyu Ekle
                </button>
            </div>

            <Table
                pagination={{
                    onChange: (pagination) => setCurentPage(pagination),
                    pageSize: 50,
                    total: data?.totalPages * 50,
                }}
                scroll={{ x: "100%" }}
                columns={columns}
                dataSource={data?.results}
            />

            <DeleteModal
                isShow={isDeleteModal}
                setIsShow={setIsDeleteModal}
                deleteUrl="api/deleteWell?wellId="
                selectedId={selectedId}
                setUpdate={setUpdate}
            />

            <Modal
                title={selectedId != null ? "Kuyu düzenle" : "Kuyu Ekle"}
                footer={null}
                open={isModal}
                destroyOnClose={true}
                onCancel={() => setIsModal((prev) => !prev)}
            >
                <AddEditWell
                    setUpdate={setUpdate}
                    setModal={setIsModal}
                    selectedId={selectedId}
                />
            </Modal>
        </div>
    )
}

export default Well