import { Col, Form, Input, Row } from "antd";
import { useEffect, useState } from "react";
import Service from "../../../../../Service";
import LoadingComp from "../../../Components/loading/LoadingComp";
import { toast } from "react-toastify";


const AddEditWell = ({ setUpdate, setModal, selectedId }) => {
    const [initalValue, setInitalValue] = useState(null);
    const [loading, setLoading] = useState(false);

    const [form] = Form.useForm();

    useEffect(() => {
        if (selectedId) {
            setLoading(true);
            const service = new Service();
            service.wellService.getwell("wellId=" + selectedId).then((res) => {
                setInitalValue(res[0]);
                setLoading(false);
            }).catch((error) => {
                toast.error(error);
            });
        }
    }, [selectedId])


    const onFinish = (value) => {
        const service = new Service();
        if (selectedId) {
            value["wellId"] = initalValue.id
            service.wellService.updatewell(value).then((res) => {
                setUpdate((prev) => !prev);
                setModal((prev) => !prev);
                toast.success(selectedId ? "Düzenleme Başarılı!" : "Kayıt eklendi.");
            }).catch((error) => {
                toast.error(error);
            });
        } else {
            service.wellService.Createwell(value).then((res) => {
                setUpdate((prev) => !prev);
                setModal((prev) => !prev);
                toast.success(selectedId ? "Düzenleme Başarılı!" : "Kayıt eklendi.");
            }).catch((error) => {
                toast.error(error);
            });
        }
    }

    if (loading) {
        return <LoadingComp />
    }

    return (
        <div>
            <Form
                layout="vertical"
                form={form}
                onFinish={onFinish}
                initialValues={initalValue}
            >
                <Row gutter={[8, 8]}>


                    <Col span={24}>
                        <Form.Item
                            label="Kuyu adı"
                            name="name"
                            rules={[{ required: true, message: "Kuyu adı boş geçilemez." }]}
                        >
                            <Input
                                placeholder="Kuyu adı"
                                className="bg-indigo-50 px-4 py-2 outline-none rounded-md w-full"
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <button
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 me-2 mb-2 rounded"
                            htmlType="submit"
                        >
                            Kaydet
                        </button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default AddEditWell