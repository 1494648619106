import { Col, Form, Input, Row } from 'antd';
import React, { useEffect, useState } from 'react'
import Service from '../../../../../Service';
import LoadingComp from '../../../Components/loading/LoadingComp';
import { toast } from 'react-toastify';

const AddUser = ({ setUpdate, setModal, selectedId }) => {
    const [initalValue, setInitalValue] = useState(null);
    const [loading, setLoading] = useState(false);

    const [form] = Form.useForm();

    useEffect(() => {
        if (selectedId) {
            setLoading(true);
            const service = new Service();
            service.UserService.getuser("userId=" + selectedId).then((res) => {
                setInitalValue(res[0]);
                setLoading(false);
            }).catch((error) => {
                toast.error(error);
            })
        }
    }, [selectedId])


    const onFinish = (value) => {
        const service = new Service();
        if (selectedId) {
            value["userId"] = initalValue.id
            service.UserService.updateUser(value).then((res) => {
                setUpdate((prev) => !prev);
                setModal((prev) => !prev);
                toast.success(selectedId ? "Düzenleme Başarılı!" : "Kayıt eklendi.");
            }).catch((error) => {
                toast.error(error);
            });
        } else {
            service.UserService.CreateUser(value).then((res) => {
                setUpdate((prev) => !prev);
                setModal((prev) => !prev);
                toast.success(selectedId ? "Düzenleme Başarılı!" : "Kayıt eklendi.");
            }).catch((error) => {
                toast.error(error);
            });
        }
    }

    if (loading) {
        return <LoadingComp />
    }

    return (
        <div>
            <Form
                layout="vertical"
                form={form}
                name="addProduct"
                onFinish={onFinish}
                initialValues={initalValue}
            >
                <Row gutter={[8, 8]}>


                    <Col span={12}>
                        <Form.Item
                            label="Ad"
                            name="firstName"
                            rules={[{ required: true, message: "Ad gereklidir." }]}
                        >
                            <Input
                                placeholder="Ad"
                                className="bg-indigo-50 px-4 py-2 outline-none rounded-md w-full"
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="Soyad"
                            name="lastName"
                            rules={[{ required: true, message: "Soyad gereklidir." }]}
                        >
                            <Input
                                placeholder="Soyad"
                                className="bg-indigo-50 px-4 py-2 outline-none rounded-md w-full"
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <button
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 me-2 mb-2 rounded"
                            htmlType="submit"
                        >
                            Kaydet
                        </button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default AddUser