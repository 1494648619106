import baseService from "./baseService";

export default class SettingsService extends baseService {
  constructor() {
    super();
    this.schemaName = "api";
  }

  getPricesPerMinute(data) {
    return this.getObject(this.schemaName, "getPricesPerMinute", data);
  }

  getCurrentPricePerMinute(data) {
    return this.getObject(this.schemaName, "getCurrentPricePerMinute", data);
  }

  addPricePerMinute(data) {
    return this.postObject(this.schemaName, "addPricePerMinute", data);
  }

  updatePricePerMinute(data) {
    return this.putObject(this.schemaName, "updatePricePerMinute", data);
  }

  deletePricePerMinute(data) {
    return this.deleteObject(this.schemaName, "deletePricePerMinute", data);
  }
}
