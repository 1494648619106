import { Col, DatePicker, Form, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import './datapicker.css'
import LoadingComp from '../../../Components/loading/LoadingComp';
import Service from '../../../../../Service';
import locale from 'antd/es/date-picker/locale/tr_TR';
import 'dayjs/locale/tr';
import dayjs from 'dayjs';
import moment from 'moment';
import { toast } from 'react-toastify';


const AddEditIrrigation = ({ setUpdate, setModal, selectedId, userId }) => {
    const [initialValue, setInitialValue] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loading1, setLoading1] = useState(false);
    const [wells, setWells] = useState([]);
    const [form] = Form.useForm();
    const [selectedCal, setSelectedCal] = useState();
    const [selectedCal2, setSelectedCal2] = useState();
    const [formData, setFormData] = useState({});


    useEffect(() => {
        setLoading(true);
        const service = new Service();
        service.wellService.getwells(`page=${-1}&pagesize=50`).then((res) => {
            setWells(res);
            console.log(res.results.map((item) => ({
                "value": item.id,
                "label": item.name
            })));
            setLoading(false);

        });
        if (selectedId) {
            form.resetFields();
            setLoading1(true);
            service.wellUserService.getIrrigation("irrigationId=" + selectedId).then((res) => {
                setLoading1(false);
                res[0].startedAt = dayjs(res[0].startedAt);
                res[0].endAt = dayjs(res[0].endAt);
                setInitialValue(res[0]);
                form.setFieldsValue(res[0]);
            }).catch((error) => {
                toast.error(error);
            });
        }
    }, [form, selectedId]);

    const handleFormChange = (changedValues, allValues) => {
        setFormData(allValues);
    };

    const onFinish = (value) => {
        const service = new Service();
        value["userId"] = userId;
        value.startedAt = dayjs(value.startedAt).format('YYYY-MM-DD HH:mm:ss');
        value.endAt = dayjs(value.endAt).format('YYYY-MM-DD HH:mm:ss');
        if (selectedId) {
            value["id"] = initialValue.id;
            service.wellUserService.updateIrrigation(value).then((res) => {
                setUpdate((prev) => !prev);
                setModal((prev) => !prev);
                toast.success(selectedId ? "Düzenleme Başarılı!" : "Kayıt eklendi.");
            }).catch((error) => {
                toast.error(error);
            });
        } else {
            service.wellUserService.createIrrigation(value).then((res) => {
                setUpdate((prev) => !prev);
                setModal((prev) => !prev);
                toast.success(selectedId ? "Düzenleme Başarılı!" : "Kayıt eklendi.");
            }).catch((error) => {
                toast.error(error);
            });
        }
    };

    return (
        <div>
            <Form
                layout="vertical"
                form={form}
                onFinish={onFinish}
                onValuesChange={handleFormChange}
                initialValues={initialValue}
            >
                <Row gutter={[8, 8]}>
                    <Col span={12}>
                        <Form.Item
                            label="Kuyu adı"
                            name="wellId"
                            rules={[{ required: true, message: "Kuyu adı boş geçilemez." }]}
                        >
                            <Select
                                size='large'
                                style={{ width: "100%" }}
                            >
                                {
                                    wells?.results?.map((item) => (
                                        <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Başlangıç Tarihi"
                            name="startedAt"
                            rules={[{ required: true, message: "Başlangıç tarihi boş geçilemez." }]}
                        >
                            <DatePicker
                                locale={locale}
                                showTime={{
                                    defaultValue: moment("00:00:00", "HH:mm:ss"),
                                    format: "DD-MM-YYYY HH:mm",
                                }}
                                size="large"
                                onSelect={(value) => {
                                    setSelectedCal(value);
                                    form.setFieldsValue({ startedAt: value });
                                    handleFormChange(
                                        { startedAt: value },
                                        { ...formData, startedAt: dayjs(value).format("YYYY-MM-DD HH:mm:ss") }
                                    );
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Bitiş Tarihi"
                            name="endAt"
                            rules={[{ required: true, message: "Bitiş tarihi boş geçilemez." }]}
                        >
                            <DatePicker
                                locale={locale}
                                showTime={{
                                    defaultValue: moment("00:00:00", "HH:mm"),
                                    format: "HH:mm"
                                }}
                                size='large'
                                onSelect={(value) => {
                                    setSelectedCal2(value);
                                    form.setFieldsValue({ endAt: value });
                                    handleFormChange(
                                        { endAt: value },
                                        { ...formData, endAt: dayjs(value).format("YYYY-MM-DD HH:mm") }
                                    );
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <button
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 me-2 mb-2 rounded"
                            htmlType="submit"
                        >
                            Kaydet
                        </button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default AddEditIrrigation;
