import React, { useEffect } from "react";

import { FaBoxes, FaUsers } from "react-icons/fa"
import { GiWell } from "react-icons/gi"
import { HiDocumentReport } from "react-icons/hi"
import { AiFillHome, AiFillSetting } from "react-icons/ai"
import { Link, NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Stores from "../../../Stores";
import SidebarItem from "./SitebarItem";


const Sidebar = () => {



    const sidebar = [
        {
            icon: <AiFillHome size={20} />,
            isActive: false,
            hasSubpages: false,
            title: "Ana sayfa",
            path: "/dashboard",
        },
        {
            icon: <GiWell size={20} />,
            isActive: false,
            hasSubpages: false,
            title: "Kuyular",
            path: "/dashboard/well",
        },
        {
            icon: <FaUsers size={20} />,
            isActive: false,
            hasSubpages: false,
            title: "Üyeler",
            path: "/dashboard/users",
        },
        {
            icon: <HiDocumentReport size={20} />,
            isActive: false,
            hasSubpages: false,
            title: "Raporlar",
            path: "/dashboard/report",
        },
        {
            icon: <AiFillSetting size={20} />,
            isActive: false,
            hasSubpages: false,
            title: "Ayarlar",
            path: "/dashboard/settings",
        },

    ];

    let sidebarToggleClass = true;
    const dispatch = useDispatch();
    const isSidebarOpen = useSelector(state => state.site.sidebar);
    const location = useLocation();



    const sidebarOpen = "block  translate-x-0";
    const sidebarClosed = "hidden  -translate-x-full";

    return (
        <aside
            id="sidebar"
            className={`
      no-scrollbar
      h-screen  transition-transform
      z-20 w-64 overflow-y-auto bg-slate-50 dark:bg-gray-800 flex-shrink-0 shadow-lg
      ${isSidebarOpen ? sidebarOpen : sidebarClosed}
    `}
        >
            <div className="py-4 text-gray-500 dark:text-gray-400 ">
                <div className="flex m-2 ">
                    <Link
                        to="/dashboard"
                        className="flex w-full justify-start items-center text-lg font-bold text-gray-800 dark:text-gray-200 flex justify-center items-center"
                    >
                        Sulama V2.1

                    </Link>
                </div>

                <ul>
                    {sidebar.map((item, index) => {
                        if (location.pathname === item.path) {
                            item.isActive = true;
                        }
                        return (
                            <span>
                                <SidebarItem
                                    key={index}
                                    icon={item.icon}
                                    isActive={item.isActive}
                                    hasSubpages={item.hasSubpages}
                                    subData={item.subData}
                                    title={item.title}
                                    path={item.path}
                                />
                            </span>
                        );
                    })}
                </ul>
                {/* <div className="px-6 my-6">
                    <button className="flex items-center justify-between w-full px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple">
                        <a href="/" target="_blank">
                            Go to the main site
                        </a>
                   
                    </button>
                </div> */}
            </div>
        </aside>
    );
};

export default Sidebar;
