import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import PrivateRouter from "./PrivateRouter";
import AuthRouter from "./AuthRouter";
import PublicRouter from "./PublicRouter";

const RouterPage = () => {


  useEffect(() => {
    if (window.location.protocol === "http:") {
      Window.location = window.location.href.replace("http", "https:");
    }
  }, []);

  return (
    <Routes>
      <Route path="/*" element={<PublicRouter />} />
      <Route path="/auth/*" element={<AuthRouter />} />
      <Route path="/dashboard/*" element={<PrivateRouter />} />
    </Routes>
  );
};

export default RouterPage;
