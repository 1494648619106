import baseService from "./baseService";

export default class DebtPaymentService extends baseService {
  constructor() {
    super();
    this.schemaName = "api";
  }

  getPaymentsForUser(data) {
    return this.getObject(this.schemaName, "getPaymentsForUser", data);
  }

  getPayment(data) {
    return this.getObject(this.schemaName, "getPayment", data);
  }

  createPayment(data) {
    return this.postObject(this.schemaName, "createPayment", data);
  }

  updatePayment(data) {
    return this.putObject(this.schemaName, "updatePayment", data);
  }
  deletePayment(data) {
    return this.deleteObject(this.schemaName, "deletePayment", data);
  }
}
