import { initializeApp } from "firebase/app";
import {
  signInWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  signOut,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  signInWithCustomToken,
} from "firebase/auth";
import { toast } from "react-toastify";

import { Decrypt, Encrypt } from "./encrypted";
import { doc, getFirestore, onSnapshot, setDoc } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { refreshToken, setToken, setlogin, setlogout } from "../Stores/auth";
import Stores from "../Stores";

const firebaseConfig = {
  apiKey: "AIzaSyDOpawgjy7bJhPdY6C9cZ-hCNnNPiZuc4A",
  authDomain: "sulama-16eb9.firebaseapp.com",
  projectId: "sulama-16eb9",
  storageBucket: "sulama-16eb9.appspot.com",
  messagingSenderId: "878531466104",
  appId: "1:878531466104:web:315e4baddb066d62b96e8d",
  measurementId: "G-BPJ113HW5M",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

export const login = async (email, password) => {
  try {
    const { user } = await signInWithEmailAndPassword(auth, email, password);
    return user;
  } catch (error) {
    console.log(error.code);
    toast.error(error.code);
  }
};

onAuthStateChanged(auth, (user) => {
  if (user) {
    user
      .getIdToken()
      .then((res) => {})
      .catch((err) => {
        Stores.dispatch(setlogout());
      });
    Stores.dispatch(setlogin(Encrypt(JSON.stringify(user))));
    Stores.dispatch(setToken(Encrypt(JSON.stringify(user.accessToken))));
  } else {
    Stores.dispatch(setlogout());
  }
});

// export const RefleshToken = () => {
//   onAuthStateChanged(auth, (user) => {
//     if (user) {
//       user
//         .getIdToken()
//         .then((res) => {})
//         .catch((err) => {
//           Stores.dispatch(setlogout());
//         });
//       Stores.dispatch(setlogin(Encrypt(JSON.stringify(user))));
//       Stores.dispatch(setToken(Encrypt(JSON.stringify(user.accessToken))));
//     } else {
//       Stores.dispatch(setlogout());
//     }
//   });
// };

export const signoutUser = async () => {
  try {
    return await signOut(auth);
  } catch (error) {
    toast.error(error.code);
  }
};

export const userResetPassword = (email) => {
  sendPasswordResetEmail(auth, email)
    .then(() => {
      return true;
    })
    .catch((error) => {
      toast.error(error.code);
    });
};

export const singUpUser = async (email, password) => {
  try {
    const { user } = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    return user;
  } catch (error) {
    toast.error(error.code);
  }
};

export const RefleshToken = async (refreshToken) => {
  signInWithCustomToken(refreshToken)
    .then((userCredential) => {
      // Yenilenmiş erişim belgesi
      var newAccessToken = userCredential.user.getIdToken();
      Stores.dispatch(setToken(Encrypt(JSON.stringify(newAccessToken))));
      console.log("Yenilenmiş Erişim Belgesi: ", newAccessToken);
      return userCredential.user;
    })
    .catch((error) => {
      // Hata işleme
      console.error("Hata: ", error);
      Stores.dispatch(setlogout());
    });
};

export const setUserData = async (
  user,
  displayName,
  referenceCode,
  hasReferenceCode,
  email,
  phone
) => {
  await setDoc(doc(db, "users", user.uid), {
    uid: user.id,
    displayName,
    email,
    referenceCode,
    hasReferenceCode,
    phone,
  });
};

export const ImgUpload = async (file, folderName, filename) => {
  const imageRef = ref(storage, folderName + filename);
  try {
    await uploadBytes(imageRef, file);
    const responseFileUrl = await getDownloadURL(imageRef);
    return responseFileUrl;
  } catch (error) {
    toast.error("error", error.message);
  }
};

export default app;
